<template>
  <div>
    <b-container>
      <div v-if="authentication">
        <b-alert
          variant="success"
          show
        >autentication ok</b-alert>
      </div>
      <div v-else>
        <b-alert
          variant="danger"
          show
        >autentication ko</b-alert>
      </div>
      <div>
        <b-alert
          variant="info"
          show
        >session key: <b>{{session_key}}</b></b-alert>
      </div>
      <div>
        <strong>state modules loaded at startup</strong> <i>(src/vuex/*)</i>
        <div class="d-flex align-items-center">
          <div>I am a global state key: {{ global_state_key }}</div>
          <b-button
            @click="global_state_key++"
            size="sm"
            variant="info"
            class="ml-2 my-1"
          >click to increment</b-button>
        </div>
        <div class="d-flex align-items-center">
          <div>I am a session state key: {{ session_state_key }}</div>
          <b-button
            @click="session_state_key++"
            size="sm"
            variant="info"
            class="ml-2 my-1"
          >click to increment</b-button>
        </div>
        <hr />
        <strong>state modules loaded at runtime</strong> <i>(src/components/submodules/*)</i>
        <br />
        <dynamic-submodule-1 />
        <dynamic-submodule-2 />
      </div>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue'
import { service_handler } from '@/services'
import DynamicSubmodule1 from '@/sites/submodules/dynamic-submodule-1/dynamic-submodule-1'
import DynamicSubmodule2 from '@/sites/submodules/dynamic-submodule-2/dynamic-submodule-2'

export default {
  name: 'test',
  components: {
    'dynamic-submodule-1': DynamicSubmodule1,
    'dynamic-submodule-2': DynamicSubmodule2,
  },
  data() {
    return {
      authentication: this.is_authenticated()
    }
  },
  computed: {
    session_key() {
      return Vue.prototype.$localstorage_session_key
    },
    global_state_key: {
      get() {
        return this.$store.state.global_state_key
      },
      set(value) {
        this.$store.commit('global_state_key', value)
      }
    },
    session_state_key: {
      get() {
        return this.$store.state.session_state_key
      },
      set(value) {
        this.$store.commit('session_state_key', value)
      }
    },
  },
  created() {
    //this.is_authenticated = is_authenticated
  },
  mounted() {
    this.authentication = this.is_authenticated()
    service_handler('otp', { test: 'test' })
      .then((data) => {
        this.log('test servizio otp chiamato con architettura centralizzata')
        this.log(data)
      })
  },
  methods: {},
};
</script>

<style lang='scss'>
.test {
}
</style>