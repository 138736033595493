<template>
  <div class="d-flex align-items-center">
    <div>I am a dynamic-submodule-1 state key: {{ dynamic_submodule_1_state_key }}</div>
    <b-button
      @click="dynamic_submodule_1_state_key++"
      size="sm"
      variant="info"
      class="ml-2 my-1"
    >click to increment</b-button>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'dynamic-submodule-1',
  props: {},
  data() {
    return {}
  },
  computed: {
    dynamic_submodule_1_state_key: {
      get() {
        return this.$store.state.dynamic_submodule_1.dynamic_submodule_1_state_key
      },
      set(value) {
        this.$store.commit(
          'dynamic_submodule_1/dynamic_submodule_1_state_key',
          value
        )
      },
    },
  },
  methods: {},
  beforeMount() {
    let store = require('./store')
    store.register_state_module(Vue.prototype)
  },
}
</script>