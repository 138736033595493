import { vuex_subscribe_module } from '@/vuex'
import { load_state_from_local_storage } from '@/resources'

let name = 'dynamic_submodule_1',
  state = {
    dynamic_submodule_1_state_key: 100,
  },
  module = {
    namespaced: true,
    state: load_state_from_local_storage(name, state),
    mutations: {
      dynamic_submodule_1_state_key(state, value) {
        state.dynamic_submodule_1_state_key = value
      },
    },
    getters: {},
    actions: {},
    get_local_storage_submodule() {
      // dichiaro le chiavi che verranno scritte nel local storage
      return {
        dynamic_submodule_1_state_key: state.dynamic_submodule_1_state_key,
      }
    },
  }

export function register_state_module(prototype) {
  if (prototype.$submodules == undefined) prototype.$submodules = {}
  prototype.$submodules[name] = {
    get_local_storage_submodule: module.get_local_storage_submodule()
  }
  vuex_subscribe_module(name, state, module)
}